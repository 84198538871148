// 播放点击时触发的音频
export function clickMusic(){
    let audio = new Audio(require('@/assets/music/bigproject_res_di.mp3'));
    // 播放音频
    audio.play();
}

// 请输入取鞋码
export function clickStoreMusic(){
    let audio = new Audio(require('@/assets/music/qingshuruquxiema.mp3'));
    // 播放音频
    audio.play();
}

// 请选择您要清洗的鞋子类型
export function clickShoesTypeMusic(){
    let audio = new Audio(require('@/assets/music/qingxuanze.mp3'));
    // 播放音频
    audio.play();
}

// 取鞋码错误
export function shoseErrorMusic(){
    let audio = new Audio(require('@/assets/music/quxiamacuowu.mp3'));
    audio.play()
}

// 微信支付
export function shosePaymentMusic(){
    let audio = new Audio(require('@/assets/music/weixinsaoma.mp3'));
    audio.play()
}

// 箱门已打开，请放入物品关闭箱门，如需继续还鞋，请扫订单物流码
export function openReturnMusic(){
    let audio = new Audio(require('@/assets/music/yikai.mp3'));
    audio.play()
}


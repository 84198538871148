import Vue from "vue";
import VueRouter from "vue-router";

import Root from "../views/root/index.vue";
import Login from "../views/login/login.vue";
import Main from "../views/root/main.vue";
import NotFound from "../views/root/NotFound.vue";

import Module1 from "../views/module1/index.vue";
import TakeShoes from "../views/module1/TakeShoes.vue";
import StoreShoes from "../views/module1/StoreShoes.vue";
import QrcodeStream from "../views/module1/qrcodeStream.vue";
import OrderNumber from "../views/module1/orderNumber.vue";
import Camera from "../views/camera/index.vue"


Vue.use(VueRouter);

const routes = [
  // { path: "/", name: "Root", component: Root },
  { path: "/Login", name: "Login", component: Login },
  // {
  //   path: '/Main',
  //   name: "Main",
  //   component: Main,
  //   // redirect: "/Module2",
  //   children: [
  //     { path: "/Module1", name: "Module1", component: Module1 },
  //   ],
  // },
  { path: "/", name: "Module1", component: Module1 },
  { path: "/TakeShoes", name: "TakeShoes", component: TakeShoes },
  { path: "/StoreShoes", name: "StoreShoes", component: StoreShoes },
  { path: "/qrcodeStream", name: "QrcodeStream", component: QrcodeStream },
  { path: "/OrderNumber", name: "OrderNumber", component: OrderNumber },
  { path: "/Camera", name: "Camera", component:Camera },




  { path: "/NotFound", name: "NotFound", component: NotFound },
  // 所有未定义路由，全部重定向到404页，必须放在最后
  {
    path: '*',
    redirect: '/404'
  }

];




const router = new VueRouter({
  routes,
  // mode: 'hash',
  // base: process.env.BASE_URL,
  // /**
  //  * 路由跳转后页面滚动到顶部
  //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  //  */
  // scrollBehavior: () => ({ y: 0 }),
});




/*

只能永久，需要手动清除
存储：window.localStorage.setItem(name, JSON.stringify(obj))
读取：window.localStorage.getItem(name)
删除：window.localStorage.removeItem(name)
清空：window.localStorage.clear()
*/


import {getToken} from "@/utils/auth";
// 路由前置守卫
router.beforeEach((to, from, next) => {
  next();
  // const { meta, name } = to;
  // const { isLogin } = meta;
  // let token = getToken();
  //
  //
  // // token不存在时跳转非登录页，重定向到登录页
  // if (!token && name !== 'Login' && !isLogin) {
  //   next({ name: 'Login' });
  // } else if (token && name === 'Login') {
  //   next({ name: 'Main' });
  // }
  // // 其他场景
  // else next();
});


export default router;

<template>
  <div class="home">
    <div class="header">
      <p>欢迎使用智能洗鞋柜</p>
      <p>{{date}}</p>
    </div>
    <div class="content">
      <div class="box-card" @click="show = true" :style="`width: ${innerWidth}px;height:${innerHeight}px`">
        <img width="100" height="100" src="../../assets/img/take-shoes.png" alt="">
        <p>我要存鞋</p>
      </div>
      <div class="box-card" @click="clickTakeShoes" :style="`width: ${innerWidth}px;height:${innerHeight}px`">
        <img width="100" height="100" src="../../assets/img/store-shoes.png" alt="">
        <p>我要取鞋</p>
      </div>
    </div>

    <video ref="video" id="video" style="width: 500px;"></video>
<!--    <canvas id="canvas" style="display:none;" :width="videoWidth" :height="videoHeight"></canvas>-->

    <div class="footer">
      <div class="left">
<!--        <img class="er" src="../../assets/img/er.jpeg" alt="">-->
        <div class="er" ref="qrCodeUrl"></div>
        <div class="tips">
          <img src="../../assets/img/arrow-left.png" alt="">
          <p>微信扫码存取鞋</p>
          <p>管理员微信扫码取鞋</p>
        </div>
      </div>
    </div>

    <!--  选择存鞋方式  -->
    <van-popup v-model="show"
               closeable
               round
               close-on-click-overlay
               custom-style="width: 80%"
               @click="show = false">
      <div class="popup">
        <div class="title">请选择您要进行的操作</div>
<!--        <div class="item flex-column-center" @click="openCamera">-->
        <router-link to="/Camera">
          <div class="item flex-column-center">

              <span>扫码存鞋</span>
              <span>柜机点击【我要存鞋】扫码存鞋</span>
          </div>
        </router-link>

        <div class="item flex-column-center" @click="clickStoreShoes">
          <span>立即下单</span>
          <span>柜机点击【我要存鞋】立即下单</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {BrowserMultiFormatReader} from '@zxing/library'
import {parseTime} from '@/utils/yiqi'
import {userSetShoes} from "../../api/shop";
import QRCode from "qrcodejs2"

export default {
  data() {
    return {
      date:'',
      timer: '',
      deviceId:"2100015906",
      videoWidth: 300,
      videoHeight: 300,
      innerWidth:'',
      innerHeight:'',
      show: false,
      codeReader: new BrowserMultiFormatReader()
    };
  },
  created() {
     this.getDate()
    this.getDeviceId()
  },
  mounted() {
    this.generateQRCode()
    console.log(window)
    let {innerHeight,innerWidth} = window
    this.innerHeight = innerHeight / 2 - 50
    this.innerWidth = innerWidth * 0.8 / 2 -50
  },
  methods: {
    // 获取设备编号
    getDeviceId(){
      const url = 'https://xixiepad.yigichuanqying.com?deviceId=2100015906#/';
      // const url = window.location.href;
      const regex = /deviceId=(\d+)/;
      const match = url.match(regex);
      const deviceId = match[1];
      this.deviceId = deviceId
      // this.$toast.fail('deviceId为：' + deviceId)
    },
    // 获取当前时间
    getDate(){
      this.timer = setInterval(() => {
        this.date = parseTime(new Date())
      },1)
    },
    // 存鞋
    clickStoreShoes(){
      clearInterval(this.timer)
      this.clickMusic()
      this.$router.push({name:'StoreShoes',query:{deviceId:this.deviceId}})
      // this.$router.push({
      //   path: '/qrcodeStream',
      //   query:{userId:'1004003'}
      // });
      // this.openCamera()
    },

    // 生成二维码
    generateQRCode() {
      // document.querySelector(".er").innerHTML = "";
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.deviceId,
        width: 180,
        height: 180,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    /**
     * 开启摄像头*/
    openCamera(type) {
      if (type == 1) {
        this.btnVisiable = true;
      } else {
        this.btnVisiable = false;
      }
      let that = this
      that.codeReader = new BrowserMultiFormatReader();
      that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        console.log('videoInputDevices', videoInputDevices);
        //查看获取到的摄像头数量
        for (let i = 0; i < videoInputDevices.length; ++i) {
          let deviceInfo = videoInputDevices[i];
          let option = document.createElement('option');
          console.log(option)
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === 'videoinput') {
            option.text = deviceInfo.label;
            // this.cameraNum.push(option)
            this.changPhoto(0)
          } else {
            console.log('Found ome other kind of source/device: ', deviceInfo);
          }
        }
      }).catch((err) => {
        this.$message.error('获取摄像头失败');
        console.error(err);
      });
    },
    /**
     * 选择摄像头*/
    changPhoto(firstDeviceId) {
      this.videoVisible = true
      const that = this
      that.changPhotoId = firstDeviceId
      // 重置
      that.codeReader.reset()
      // 选择摄像头后进行识别
      that.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
        if (result) {
          // this.identifyCode(result)
          let data = JSON.parse(result.text)
          console.log(data)
          console.log()
          if(this.deviceId == data.storageNumber){
            this.$toast.fail('不是此柜体的订单')
            return
          }
          userSetShoes({orderNumber:data.orderNumber,storageName:data.deviceNumber}).then(res => {
            console.log(res,'开箱成功')
            this.codeReader.reset();
            this.videoVisible = false;
            this.clickMusic()
          }).catch(err => {
            this.codeReader.reset();
            this.videoVisible = false;
          })
          //识别成功后进行停止识别（类似截图）
          // let video = document.getElementById('video');
          // let canvas = document.getElementById('canvas');
          // let context = canvas.getContext('2d');
          // context.drawImage(video, 0, 0, 240, 300);
          // this.$createDialog().show()
        }
        if (err && !(err)) {
          console.error(err);
        }
      });
    },

    // 取鞋
    clickTakeShoes(){
      clearInterval(this.timer)
      this.clickMusic()
      this.$router.push({name:'TakeShoes',query:{deviceId:this.deviceId}})
    }
  },

};
</script>

<style lang="less" scoped>
.home{
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 0;
  position: fixed;
}
.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content{
  display: flex;
  justify-content: center;
}

.box-card{
  //width: 400px;
  //height: 400px;
  background: #b2affa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  border-radius: 10px;
}

.footer{
  position: fixed;
  bottom: 30px;
}

.left{
  display: flex;
  align-items: flex-end;
}

.left .er {
  display: inline-block;
  width: 180px;
  height:180px;
  background-color: red;
  box-sizing: border-box;
  margin-right: 30px;
}

.tips img{
  width: 12px;
  height: 20px;
  border: solid 2px #333333;
  padding: 5px 10px;
  border-radius: 50%;
}
.tips p {
  line-height: 4px;
}

.popup{
  /* width: 80%; */
  padding: 80px 50px;
}

.popup .title{
  /* background-color: red; */
  margin-bottom: 100px;
  font-size: 28px;
  color: #333333;
}

.popup .item{
  padding:20px 40px;
  border: solid #E4E3E3 1px;
  border-radius: 20px;
  margin-top: 30px;
}

.popup .item span:nth-child(1){
  font-size: 28px;
  font-weight: 700;
  color: #333333;
}

.popup .item span:nth-child(2){
  font-size: 20px;
  color: #121212;
  margin-top: 20px;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

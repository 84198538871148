<template>
    <div>
      <div class="nav-title">
        <div class="arrow" @click="clickReturn">返回</div>
        <p>请输入取鞋码</p>
        <div class="arrow">{{ countdown }}s</div>
      </div>
      <div class="contnet">
        <div class="left">
          <div class="item" v-for="(item,index) in signList" :key="index">{{ item }}</div>
        </div>
        <div class="right">
          <div class="item" v-for="(item,index) in keyWordList" :key="index" @click="clickInput(item)">
            <img class="back" v-if="item.type == 'back'" src="../../assets/img/back.png" alt="">
            <img class="del" v-if="item.type == 'delete'" src="../../assets/img/del.png" alt="">
            <p v-if="item.type == 'num'">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import {userTakeShoes} from "../../api/shop";
import {openReturnMusic, shoseErrorMusic} from "../../utils/music";

export default {
  name: "TakeShoes",
  data(){
    return{
      countdown: 60,
      index: 0,
      timer:"",
      deviceId:"",  // 设备id
      signList:['','','','','',''],
      keyWordList:[
        {value:'1',type: 'num'},
        {value:'2',type: 'num'},
        {value:'3',type: 'num'},
        {value:'',type: 'back'},
        {value:'4',type: 'num'},
        {value:'5',type: 'num'},
        {value:'6',type: 'num'},
        {value:'0',type: 'num'},
        {value:'7',type: 'num'},
        {value:'8',type: 'num'},
        {value:'9',type: 'num'},
        {value:'',type: 'delete'},
      ]
    }
  },
  created() {
    this.deviceId = this.$route.query.deviceId
    this.clickStoreMusic()
    this.timeFn()
  },
  methods:{
    // 输入
    clickInput(item){
      this.clickMusic()
      if(item.type == 'back'){
        this.signList.splice(this.index-1,1,'')
        this.index = this.index - 1
      }
      if(item.type == 'delete'){
        this.signList = ['','','','','','']
        this.index = 0
      }
      if(item.type == 'num'){
        if(this.index < this.signList.length){
          this.signList.splice(this.index,1,item.value)
          this.index++
        }else {
          return
        }
      }
      let pickupCode = this.signList.join('')
      if(pickupCode.length == 6){
        let data = {
          pickupCode:pickupCode,
          number:this.deviceId
        }
        console.log(data)
        userTakeShoes(data).then(res => {
          this.$toast.success('输入成功')
          this.openReturnMusic()
        }).catch(err => {
          this.shoseErrorMusic()
          this.signList = ['','','','','','']
        })
      }
    },
    // 返回
    clickReturn(){
      this.clickMusic()
      this.$router.back()
    },
    // 倒计时
    timeFn() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.$router.back()
        }
      }, 1000);
    },

  }
}
</script>

<style scoped>
.nav-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.nav-title .arrow{
  padding: 10px 20px;
  box-shadow: 0 2px 4px 4px #e2e2e2;
  border-radius: 5px;
}

.contnet{
  display: flex;
  align-items: center;
}

.contnet .left {
  width: 60%;
  padding: 50px 20px;
  box-sizing: border-box;
  /*background: red;*/
  display: flex;
  align-items: center;
}

.contnet .left .item {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background: #3066a280;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  color: white;
}

.contnet .right{
  width: 38%;
  padding: 50px 20px;
  box-sizing: border-box;
  /*background: red;*/
  display: flex;
  flex-wrap: wrap;
}

.contnet .right .item {
  width: 100px;
  height: 80px;
  border-radius: 5px;
  background: #3066a2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  color: white;
}

.item .back{
  width: 40px;
  height: 30px;
}
.item .del{
  width: 40px;
  height: 40px;
}
</style>
